import WhyInvestmentTimeLine from "./WhyInvestmentTimeLine";
import WhyInvestmentTable from "./WhyInvestmentTable";
import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";

export const WhyInvestment = () => {
  return (
    <section
      id="home-why-invest"
      className="grid overflow-x-clip bg-primary-green bg-[url('/public/assets/images/backgrounds/background-giant-blob.png')] bg-cover bg-center bg-no-repeat"
    >
      <div className="lg:single-grid section-padding container lg:py-0">
        <div className="lg:section-padding single-grid-item grid gap-10 lg:grid-cols-2">
          <div className="flex h-full flex-col">
            <div className="mb-10 flex flex-wrap gap-2 text-section-heading font-bold">
              <h2 className="py-1.5 text-white">Why Investment in</h2>
              <ThreeNeedlesHeading
                backgroundColor="white"
                needlesColor="black"
                className="text-primary-green"
              >
                Agriculture
              </ThreeNeedlesHeading>
              <SvgArrowCurved className="-ml-4 mt-4 w-24 rotate-6 max-md:hidden" />
            </div>
            <div className="relative flex -translate-x-1/4 flex-col max-xl:mx-6 max-lg:hidden">
              <img
                className="w-1/2 max-w-sm self-end rounded-full"
                src="/assets/images/pages/home/why-investment-1.jpg"
                alt=""
              />
              <img
                className="w-3/4 -translate-y-1/4 rounded-full"
                src="/assets/images/pages/home/why-investment-2.jpg"
                alt=""
              />
              <img
                className="absolute left-0 top-0 z-10 w-1/4 translate-x-full translate-y-1/2 rounded-full"
                src="/assets/images/common/safe-investment.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="single-grid-item flex flex-col">
          <WhyInvestmentTimeLine />
          <WhyInvestmentTable />
        </div>
      </div>
    </section>
  );
};
export default WhyInvestment;
