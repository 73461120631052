import SvgArrowStraight from "../../svgs/arrows/ArrowStraight";
import SvgAradinaFull from "../../svgs/logos/AradinaFull";
import FooterContact from "./Contact";
import FooterNavigation from "./Navigation";
import FooterNewsletter from "./Newsletter";
import FooterSocials from "./Socials";

export const Footer = () => {
  return (
    <footer
      id="site-footer"
      className="bg-[#1E3507] bg-[url('/public/assets/images/backgrounds/logo-aradina-icon-semi-transparent-white.png')] bg-[length:300px] bg-right-bottom bg-no-repeat"
    >
      <div className="container relative section-padding">
        <SvgAradinaFull className="mb-6 w-40 fill-white" />
        <div className="grid gap-10 text-xl text-white md:grid-cols-2 lg:grid-cols-4">
          <FooterNavigation />
          <FooterSocials />
          <div className="flex flex-col justify-between md:col-span-2">
            <FooterNewsletter />
            <FooterContact />
          </div>
        </div>
      </div>
      <button
        title="Scroll to top"
        type="button"
        onClick={() => window.scroll(0, 0)}
      >
        <div className="absolute bottom-5 right-5 box-content grid h-6 w-6 place-items-center rounded-full border border-white p-2.5">
          <SvgArrowStraight className="h-full rotate-180" />
        </div>
      </button>
    </footer>
  );
};
export default Footer;
