import ThreeNeedlesHeading from "../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../components/svgs/arrows/ArrowCurved";
import { homeData } from "../data";
export const HowItWorks = () => {
  return (
    <section
      id="home-how-it-works"
      className="section-padding relative bg-primary-green bg-[url('/public/assets/images/backgrounds/background-dots.png')] bg-cover bg-no-repeat text-white"
    >
      <div className="absolute left-0 top-0 grid aspect-square w-max -translate-x-10 -translate-y-1/3 scale-75 place-items-center rounded-full bg-black bg-opacity-50 p-6 max-md:hidden">
        <h2 className="gradient-text mt-3 bg-gradient-to-r from-white to-light-green text-center text-3xl font-bold leading-tight">
          Expandable <br />
          over Time
        </h2>
      </div>
      <div className="container flex flex-col gap-4">
        <div className="flex flex-wrap gap-2 self-end text-section-heading font-bold">
          <SvgArrowCurved className="mr-2 mt-2 w-20 -scale-x-100 max-md:hidden" />
          <h2 className="py-1.5">How It</h2>
          <ThreeNeedlesHeading
            className="text-primary-green"
            backgroundColor="white"
            needlesColor="black"
          >
            Works
          </ThreeNeedlesHeading>
        </div>
        <p className="w-3/4 font-bold text-white">
          <span className="uppercase">Aradina</span> is a farmer-owned, equity
          crowdfunding platform focused on specialty crop operations in the
          Lebanon. We offer fractional farmland ownership opportunities to our
          members.
        </p>
        <div className="grid gap-x-4 gap-y-10 py-10 md:grid-cols-2 xl:grid-cols-4">
          {homeData.howItWorks.map(({ number, title, content }, index) => (
            <div
              key={`how_it_works_card_${index}`}
              className="flex flex-col items-center gap-6 rounded-xl bg-[#769656] px-6 py-8"
            >
              <div className="-mt-16 grid aspect-square h-24 w-24 place-items-center rounded-full bg-white">
                <span className="mt-2 text-section-heading text-primary-green">
                  {number}
                </span>
              </div>
              <h3 className="text-section-heading font-semibold text-white">
                {title}
              </h3>
              <p className="text-center font-thin text-white">{content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default HowItWorks;
