import SvgLocation from "../../svgs/icons/Location";

const FooterContact = () => {
  return (
    <address className="mt-4 grid grid-cols-[auto_1fr] gap-4">
      <SvgLocation className="w-5 fill-white" />
      <p className="text-xl text-white">
        Ghazir, Jounieh Highway, <br />
        GAF & CO bld. 3rd fl. Kesrouan, Lebanon
      </p>
    </address>
  );
};

export default FooterContact;
