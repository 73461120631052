export const overviewPageData = {
  aidSustainablityText:
    "In our modern times an organization would greatly benefit if it can have such a visible and direct social impact which Aradina passes with flying colors",
  ourVision:
    "Lorem ipsum dolor sit amet consectetur. Quisque malesuada eget tellus turpis sed aliquam.",
  ourMission:
    "Lorem ipsum dolor sit amet consectetur. Quisque malesuada eget tellus turpis sed aliquam.",
  ourMantra:
    "Lorem ipsum dolor sit amet consectetur. Quisque malesuada eget tellus turpis sed aliquam.",
  agtechCompany: [
    "Aradina will plan the experience of the customer - Understand their channels, their behavior across all channels, and create a detailed plan on how they want the experience to flow across all the touchpoints.",
    "Use data as a base while strategizing - With the help of CRM data, social listening data, and customer's online search behavior, we will know how our customer behaves and can accordingly create a solution to address their challenges. We can even reduce the churn by using the data in the right way.",
    "Segment the users and personalize the journey. Once we analyze the data, we can easily segment users into different categories based on common behavior patterns. This will help us to create personalized journeys for each type of customer.",
  ],
  aradinaEmpowers: [
    "Societies",
    "Rural communities",
    "Creating better lives",
    "Sustaining the environment",
    "Providing opportunities",
    "Building hope",
    "Achieving bonds",
    "To be practical enabling return on investment for both farmers, investors and all other stakeholders",
  ],
  cards: [
    {
      title: "Expertise",
      content: [
        "Our offerings are carefully qualified by experts in agricuture.",
        "Expansive and comprehensive due diligence on each investment opportunity to qualify for listing.",
        "Experienced farmers with networks to producers and distributors.",
      ],
    },
    {
      title: "Transperancy",
      content: [
        "Open communication, complete disclosure.",
        "No fone print or hidden terms.",
        "Advanced enabling technologies that allow for real time access to dashboard tailored for each investor.",
      ],
    },
    {
      title: "Returns",
      content: [
        "Almost guaranteed returns.",
        "Risks are mitigated by diligence and insurance.",
        "Adavnced calculators and clear exit startegies.",
      ],
    },
  ],
  investmentFramwork:
    "Designing a framework that helps the investment organizational units (i.e., its departments, divisions, and standing committees) to follow up on measuring its strategic and procedural goals, by relying on integrated performance standards and indicators, to benefit from its readings in activating administrative, technical and financial decision-making.",
  about: [
    "Harvesting the abundance of mother earth and its grazing inhabitants of its vast farms.",
    "Rooting people in their lands and preventing adverse circumstances from forcing them to be uprooted.",
    "Love of country , nature , people and growing in healthy abundant societies.",
  ],
};

export default overviewPageData;
