export const headerNavigation = [
  {
    id: "navigation_home",
    title: "Home",
    path: "/",
  },
  {
    id: "navigation_about_us",
    title: "About Us",
    parentPath: "/about",
    children: [
      {
        id: "navigation_about_overview",
        title: "Overview",
        path: "/about/overview",
      },
      {
        id: "navigation_about_team",
        title: "Team",
        path: "/about/team",
      },
      {
        id: "navigation_about_partners",
        title: "Our Partners",
        path: "/about/partners",
      },
      {
        id: "navigation_about_media",
        title: "Media",
        path: "/about/media",
      },
      {
        id: "navigation_about_careers",
        title: "Careers",
        path: "/about/careers",
      },
      {
        id: "navigation_about_contact",
        title: "Get in Touch",
        path: "/about/contact",
      },
      {
        id: "navigation_about_faq",
        title: "FAQs",
        path: "/about/faqs",
      },
    ],
  },
  {
    id: "for_investors_navigation",
    title: "For Investors",
    path: "/investors",
  },
  {
    id: "for_farmers_navigation",
    title: "For Farmers",
    parentPath: "/farmers",
    children: [
      {
        id: "navigation_farmers_empowerment",
        title: "Empowerment",
        path: "/farmers/empowerment",
      },
      {
        id: "navigation_farmers_fundraising",
        title: "Fundraising",
        path: "/farmers/fundraising",
      },
      {
        id: "navigation_farmers_investment",
        title: "Investment",
        path: "/farmers/investment",
      },
    ],
  },
  {
    id: "resources_navigation",
    title: "Resources",
    path: "/resources",
  },
];

export const footerNavigation = [
  {
    id: "footer_home",
    title: "Home",
    path: "/",
  },
  {
    id: "footer_about_us",
    title: "About us",
    path: "/about/overview",
  },
  {
    id: "footer_invest_now",
    title: "Invest now",
    path: "/investors",
  },
  {
    id: "footer_for_farmers",
    title: "For farmers",
    path: "/farmers/empowerment",
  },
  {
    id: "footer_resources",
    title: "Resources",
    path: "/resources",
  },
];

export const userHeaderNavigation = [
  {
    id: "navigation_user_offerings",
    title: "Offerings",
    path: "/user/offerings",
  },
  // {
  //   id: "navigation_user_portfolio",
  //   title: "Portfolio",
  //   path: "/user/portfolio",
  // },
  // {
  //   id: "navigation_user_dashboard",
  //   title: "Dashboard",
  //   path: "/user/dashboard",
  // },
  // {
  //   id: "navigation_user_documents",
  //   title: "My Documents",
  //   path: "/user/documents",
  // },
];

export const userOfferingsNavigation = [
  { path: "/user/offerings/todays-picks", title: "Today's Picks" },
  { path: "/user/offerings/belongings", title: "View all" },
  { path: "/user/offerings/my-belongings", title: "My Belongings" },
  { path: "/user/offerings/sell", title: "Sell now" },
];

export const wishlistNavigation = [
  {
    path: "/wishlist/user",
    title: "Aradina Investment",
  },
  {
    path: "/wishlist/marketplace",
    title: "Marketplace",
  },
];

export const cartNavigation = [
  {
    path: "/cart/user",
    title: "Aradina Investment",
  },
  {
    path: "/cart/marketplace",
    title: "Marketplace",
  },
];

export const navigationUser = [
  {
    path: "/user/account",
    title: "Account",
  },
  {
    path: "/user/profile",
    title: "Profile",
  },
  {
    path: "/user/users",
    title: "Users",
  },
  {
    path: "/user/payment",
    title: "Payment Method",
  },
  {
    path: "/user/purchases",
    title: "My Purchases",
  },
];
