import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";

const GlobalizedMarketplace = () => {
  return (
    <section id="farmers-globalized-maketplace" className="bg-primary-green">
      <div className="container grid lg:grid-cols-[1fr_3fr]">
        <img
          className="h-full -translate-x-2/3 scale-125 object-contain"
          src="/assets/images/pages/farmers/globalized-marketplace.png"
          alt=""
        />
        <div className="my-10 flex flex-col items-end text-right text-white">
          <div className="mb-6 flex flex-col flex-wrap items-end gap-x-2 text-2xl leading-normal md:text-section-heading">
            <h2>Agricultural Financing in an Evolving</h2>
            <div className="flex items-center">
              <SvgArrowCurved className="z-10 -mr-2 w-20 -scale-x-100 max-md:hidden" />
              <ThreeNeedlesHeading
                backgroundColor="white"
                className="font-extrabold text-primary-green"
              >
                Globalized Marketplace
              </ThreeNeedlesHeading>
            </div>
          </div>
          <p>
            We understand farmers and ranchers need every cutting edge they can
            get today, on the production side and on the finance side.
            Increasingly, they're competing not just with the farm in the next
            state and county, but with farms in emerging countries. The
            agricultural industry, especially producers, must evolve to fit with
            today's globalized marketplace. And that's where we come in to help.
          </p>
        </div>
      </div>
    </section>
  );
};

export default GlobalizedMarketplace;
