import ThreeNeedlesHeading from "../../common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../svgs/arrows/ArrowCurved";
import Testimonials from "./Testimonials";

const TestimonialsSection = ({
  type = "investors",
  page,
}: {
  type?: "investors" | "farmers" | "partners";
  page: string;
}) => {
  return (
    <section
      id={`${page}_${type}-testimonials`}
      style={{
        backgroundImage: `url('/assets/images/backgrounds/testimonials/${type}.jpg')`,
      }}
      className="section-padding bg-cover bg-no-repeat"
    >
      <div className="container">
        <div className="mb-8 flex flex-wrap gap-x-2 text-section-heading font-bold">
          <h2 className="py-1.5 capitalize text-white">{type}</h2>
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            needlesColor="black"
            className="text-white"
          >
            Testimonials
          </ThreeNeedlesHeading>
          <SvgArrowCurved className="z-10 -ml-4 mt-2 w-24 max-md:hidden" />
        </div>
        <Testimonials type={type} />
      </div>
    </section>
  );
};

export default TestimonialsSection;
