import ButtonPrimary from "../../../components/common/buttons/Button";
import ThreeNeedlesHeading from "../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../components/svgs/arrows/ArrowCurved";
import { Link } from "react-router-dom";
import { homeData } from "../data";

export const InNeedOfCapital = () => {
  return (
    <section
      id="home-need-capital"
      className="section-padding relative overflow-x-clip bg-primary-yellow bg-[url('/public/assets/images/backgrounds/background-blobs.png')] bg-cover text-white"
    >
      <div className="container text-right text-section-heading">
        <div className="flex flex-wrap justify-center gap-x-2 max-md:text-center md:justify-end">
          <SvgArrowCurved className="mt-2 w-24 -scale-x-100 fill-dark-yellow max-md:hidden" />
          <h2 className="py-1.5">Are you a</h2>
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="rgb(204, 153, 0)"
            className="text-primary-yellow"
          >
            farmer
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">or</h2>
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="rgb(204, 153, 0)"
            className="text-primary-yellow"
          >
            rancher
          </ThreeNeedlesHeading>
        </div>
        <h2 className="py-1.5 text-center leading-none md:text-right">
          in need of capital?
        </h2>
      </div>
      <div className="xl:single-grid my-10 gap-10 max-xl:grid">
        <div className="xl:single-grid-item grid max-xl:hidden xl:grid-cols-[7fr_5fr]">
          <div className="flex flex-col items-center xl:col-start-2 xl:items-end">
            <img
              src="assets/images/pages/home/need-of-capital.png"
              alt=""
              className="max-w-xl origin-top-left object-contain xl:scale-125"
            />
            <img
              src="/assets/images/common/safe-investment.png"
              alt=""
              className="max-xl:hidden"
            />
          </div>
        </div>
        <div className="xl:single-grid-item container grid xl:grid-cols-[7fr_5fr]">
          <div>
            <p className="mb-8">
              <span className="font-bold">Aradina</span> offers an alternative
              funding option to the traditional bank loan route by connecting
              farmers and cattle breeders to experienced, passive investors with
              a passion for agriculture. You keep control of the operation while
              raising the capital needed to fund growth.
            </p>
            <div className="relative font-extrabold">
              Benefits
              <SvgArrowCurved className="absolute w-20 -translate-x-full translate-y-full rotate-[100deg] -scale-y-100 fill-dark-yellow" />
            </div>
            <div className="my-4 grid auto-rows-fr gap-[4rem_2rem] py-4 lg:grid-cols-2">
              {homeData.benefits.map(({ label, icon }, index) => (
                <article
                  key={`capital_benefits_${index}`}
                  className="relative grid place-items-center rounded-xl bg-[#CC9900] p-14 text-center shadow-[10px_10px_rgba(255,255,255,0.2)]"
                >
                  <h3>{label}</h3>
                  <div className="absolute right-0 top-0 grid aspect-square -translate-y-1/2 place-items-center rounded-full bg-gradient-to-b from-transparent to-gray-100 p-5">
                    {icon({ className: "w-9" })}
                  </div>
                </article>
              ))}
            </div>{" "}
            <Link to="/farmers/fundraising">
              <ButtonPrimary>Apply for Funding</ButtonPrimary>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
export default InNeedOfCapital;
