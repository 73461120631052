import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import overviewPageData from "../data";

const AgtechCompany = () => {
  return (
    <section
      id="overview-agtech-company"
      className="single-grid relative bg-primary-yellow bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-[length:50%] bg-bottom bg-no-repeat text-white"
    >
      <img
        className="absolute bottom-0 right-4 w-20"
        src="/assets/images/pages/about/overview/agtech-tree.png"
        alt=""
      />
      <div className="single-grid-item grid grid-cols-[1fr_3fr] max-lg:hidden">
        <img
          className="origin-top-right scale-125 max-lg:hidden"
          src="/assets/images/pages/about/overview/agtech-company.png"
          alt=""
        />
      </div>
      <div className="single-grid-item section-padding container grid lg:grid-cols-[1fr_3fr] lg:text-right">
        <div className="col-start-2 flex flex-col max-md:gap-2 lg:items-end">
          <div className="flex flex-wrap gap-x-2 text-section-heading">
            <h2 className="py-1.5">Aradina is a global</h2>
            <ThreeNeedlesHeading
              backgroundColor="white"
              needlesColor="black"
              className="font-bold text-primary-yellow"
            >
              Agtech company
            </ThreeNeedlesHeading>
          </div>
          <div className="mb-4 flex flex-wrap gap-x-2 text-section-heading">
            <h2 className="py-1.5">focused on</h2>
            <ThreeNeedlesHeading
              backgroundColor="white"
              needlesColor="black"
              className="font-bold text-primary-yellow"
            >
              investments
            </ThreeNeedlesHeading>
          </div>
          <ul className="z-10">
            {overviewPageData.agtechCompany.map((text, index) => {
              return (
                <li key={`overview_agtech_${index}`} className="mb-2">
                  <p>• {text}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AgtechCompany;
