import overviewPageData from "../data";

const VisionMissionMantra = () => {
  return (
    <section
      id="overview-vision-mission-mantra"
      className="bg-[url(/public/assets/images/pages/about/overview/vision-mission-background.jpg)] bg-cover bg-right bg-no-repeat"
    >
      <div className="container">
        <div className="grid items-center gap-2 pt-4 text-primary-green lg:grid-cols-[3fr_1fr]">
          <p>{overviewPageData.aidSustainablityText}</p>
          <h4 className="relative h-max w-max text-2xl font-bold text-black after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:rotate-3 after:bg-primary-green after:content-[''] max-lg:justify-self-end">
            & Aid Sustainability
          </h4>
        </div>
        <div className="grid auto-rows-fr gap-6 py-12">
          <div className="max-w-lg rounded-lg bg-white p-8 shadow-md">
            <h3 className="mb-2 text-3xl font-extrabold text-primary-green">
              Our Vision
            </h3>
            <p>{overviewPageData.ourVision}</p>
          </div>
          <div className="max-w-lg rounded-lg bg-white p-8 shadow-md">
            <h3 className="mb-2 text-3xl font-extrabold text-primary-green">
              Our Mission
            </h3>
            <p>{overviewPageData.ourMission}</p>
          </div>
          <div className="max-w-lg rounded-lg bg-white p-8 shadow-md">
            <h3 className="mb-2 text-3xl font-extrabold text-primary-green">
              Our Mantra
            </h3>
            <p>{overviewPageData.ourMantra}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMissionMantra;
