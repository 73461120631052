import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";
import PageBanner from "../../../../components/section/PageBanner";
import SvgCrop from "../../../../components/svgs/icons/Crop";

const Banner = () => {
  return (
    <PageBanner
      id="farmers-investment"
      imageUrl="/assets/images/pages/how-investment-works/banner-background.jpg"
    >
      <div className="grid place-items-center">
        <div className="container z-10 flex flex-col items-center gap-2">
          <BannerGradientHeading title="How Investment Works" />
        </div>
      </div>
      <div className="absolute right-0 top-0 flex translate-x-1/4 flex-col">
        <img
          className="w-3/4 self-end max-md:hidden"
          src="/assets/images/common/logo-green-circle-waves.png"
          alt=""
        />
        <SvgCrop className="absolute bottom-0 w-1/3 translate-x-1/2 translate-y-1/2" />
      </div>
      <img
        className="absolute bottom-0 left-0 z-10 translate-y-1/3"
        src="/assets/images/common/safe-investment.png"
        alt="Safe Investment"
      />
    </PageBanner>
  );
};

export default Banner;
