import { twMerge } from "tailwind-merge";
import { FC, ReactNode } from "react";

const ThreeNeedlesHeading: FC<
  { children: ReactNode } & { backgroundColor: string } & {
    needlesColor?: string;
  } & {
    className?: HTMLHeadingElement["className"];
  }
> = ({ children, backgroundColor, needlesColor, className }) => {
  return (
    <div
      className={`relative bg-${backgroundColor} h-max w-max rounded-lg px-4 py-1.5 mb-2`}
    >
      <h2 className={twMerge("-mb-2 text-section-heading", className)}>
        {children}
      </h2>
      {needlesColor && (
        <svg
          className="absolute -right-4 -top-1/2 h-max w-16"
          width="58"
          height="52"
          viewBox="0 0 58 52"
          fill={needlesColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="M12.0928 22.0765C11.5824 18.558 11.2268 15.0843 10.0609 11.6974C9.81912 10.9945 8.79069 11.1833 8.68193 11.8659C8.08537 15.4523 8.53292 18.8893 9.13918 22.4381C9.54901 24.8246 9.47352 29.3756 11.9705 30.5049C12.1175 30.5681 12.2804 30.5185 12.3856 30.4005C14.1141 28.4336 12.4292 24.4313 12.0837 22.0725L12.0928 22.0765Z" />
            <path d="M30.2541 27.7384C31.8035 21.9385 34.3461 15.8801 34.953 9.89213C35.0691 8.71187 33.5963 8.4154 33.0591 9.35996C30.3572 14.0433 29.1518 19.9042 27.602 25.0726C25.9546 30.5691 24.2403 35.9933 24.1091 41.7614C24.0867 42.6228 25.1877 42.9987 25.5974 42.1735C27.8672 37.5327 28.9345 32.701 30.2581 27.7292L30.2541 27.7384Z" />
            <path d="M52.1447 29.2897C49.3692 30.7296 46.667 32.3535 44.1194 34.1747C41.3522 36.1517 37.78 38.2285 35.8361 41.0499C35.2281 41.9313 36.0247 43.0145 37.0494 42.6826C39.9922 41.7393 42.4882 39.3298 44.9505 37.5046C47.8216 35.3872 50.5027 33.003 52.999 30.4413C53.5067 29.9195 52.7926 28.948 52.1447 29.2897Z" />
          </g>
          <defs>
            <clipPath id="clip0_718_1454">
              <rect
                width="48.11"
                height="34.82"
                fill="white"
                transform="translate(13.7676) rotate(23.2895)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default ThreeNeedlesHeading;
