import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import ButtonPrimary from "../../../../components/common/buttons/Button";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { Link } from "react-router-dom";
import { benefits } from "../data";

const BenefitsCards = () => {
  return (
    <div className="my-4 grid auto-rows-fr gap-x-10 gap-y-14 sm:grid-cols-2">
      {benefits.map(({ label, icon }, index) => (
        <article
          key={`farmers_benefits_${index}`}
          className="relative grid place-items-center rounded-xl bg-[#CC9900] p-12 text-center shadow-lg"
        >
          <h3 className="font-extrabold">{label}</h3>
          <div className="absolute right-0 top-0 grid aspect-square -translate-y-1/2 translate-x-1/4 place-items-center rounded-full bg-[#ECBD00] bg-opacity-75 p-5">
            {icon({ className: "w-10" })}
          </div>
        </article>
      ))}
    </div>
  );
};

const Benefits = () => {
  return (
    <section
      id="farmers-benefits"
      className="section-padding overflow-x-hidden bg-[url('/public/assets/images/backgrounds/giant-blob-yellow.png')] bg-cover bg-left-bottom bg-no-repeat text-white"
    >
      <div className="container grid 2xl:grid-cols-[4fr_3fr]">
        <div className="flex flex-col justify-end">
          <BenefitsCards />
          <ButtonPrimary className="mt-10 h-max w-max">
            <Link to="/farmers/fundraising">Apply for Funding</Link>
          </ButtonPrimary>
        </div>
        <div className="max-2xl:row-start-1">
          <div className="mb-6 flex justify-end text-section-heading">
            <SvgArrowCurved className="-mr-2 mt-2 w-20 -scale-x-100 fill-dark-yellow" />
            <ThreeNeedlesHeading
              className="text-white"
              backgroundColor="primary-yellow"
            >
              Benefits
            </ThreeNeedlesHeading>
          </div>
          <img
            className="max-2xl:-mt-20 max-md:-mt-10 2xl:translate-x-1/2"
            src="/assets/images/pages/farmers/empowerment/benefits.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Benefits;
